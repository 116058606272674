import Avatar from '@mui/material/Avatar';
// components
import { NETWORK_ICON } from '../../../config';


// ----------------------------------------------------------------------
const ImgIcon = (name) => (
  <Avatar src={`/icons/${name}`} sx={{ width: 22, height: 22 }} alt="" />
);

const ICONS = {
  main: ImgIcon(NETWORK_ICON),
  steak: ImgIcon('steak.png')
};

const sidebarConfig = [
  {
    subheader: '',
    items: [
      // TERRA
      // ----------------------------------------------------------------------
      {
        title: 'Terra',
        path: '/terra',
        icon: ICONS.main,
        expandMenu: true,
        children: [
          { title: 'Stats', path: '/terra/stats', isNew: false },
          { title: 'Validators', path: '/terra/validators' },
          { title: 'Supply', path: '/terra/supply' },
          { title: 'Community Pool', path: '/terra/communitypool' },
          // { title: 'Wallets', path: '/terra/wallets' },
          { title: 'Transactions', path: '/terra/transactions' },
          { title: 'Decentralization', path: '/terra/decentralization' },
          { title: 'Tools', path: '/terra/tools' },
        ],
      },
      // STEAK
      // ----------------------------------------------------------------------
      { title: 'Steak', path: '/steak', icon: ICONS.steak },
    ],
  },
];

export default sidebarConfig;
